var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('validation-observer',{ref:"observer"},[_c('validation-provider',{attrs:{"vid":"answer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.question.numeric)?_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
          currency: 'BRL',
          locale: 'pt-BR',
          autoDecimalMode: true,
          valueRange: { min: 0 },
          allowNegative: false
        }),expression:"{\n          currency: 'BRL',\n          locale: 'pt-BR',\n          autoDecimalMode: true,\n          valueRange: { min: 0 },\n          allowNegative: false\n        }"}],attrs:{"value":_vm.value[_vm.question.id] ? _vm.value[_vm.question.id].answer : '',"filled":""},on:{"input":function($event){return _vm.update('answer', $event)}}}):(_vm.question.mask)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.question.mask),expression:"question.mask"}],attrs:{"value":_vm.value[_vm.question.id] ? _vm.value[_vm.question.id].answer : '',"hint":_vm.question.mask,"filled":""},on:{"input":function($event){return _vm.update('answer', $event)}}}):_c('v-textarea',{attrs:{"value":_vm.value[_vm.question.id] ? _vm.value[_vm.question.id].answer : '',"counter":_vm.question.characterLimit || null,"maxlength":_vm.question.characterLimit || null,"error-messages":errors,"rows":_vm.rows,"hide-details":"auto","filled":"","auto-grow":""},on:{"input":function($event){return _vm.update('answer', $event)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }