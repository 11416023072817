<template>
  <v-col>
    <validation-observer ref="observer">
      <validation-provider
        v-slot="{ errors }"
        vid="answer"
      >
        <v-text-field
          v-if="question.numeric"
          :value="value[question.id] ? value[question.id].answer : ''"
          v-currency="{
            currency: 'BRL',
            locale: 'pt-BR',
            autoDecimalMode: true,
            valueRange: { min: 0 },
            allowNegative: false
          }"
          filled
          @input="update('answer', $event)"
        />

        <v-text-field
          v-else-if="question.mask"
          v-mask="question.mask"
          :value="value[question.id] ? value[question.id].answer : ''"
          :hint="question.mask"
          filled
          @input="update('answer', $event)"
        />

        <v-textarea
          v-else
          :value="value[question.id] ? value[question.id].answer : ''"
          :counter="question.characterLimit || null"
          :maxlength="question.characterLimit || null"
          :error-messages="errors"
          :rows="rows"
          hide-details="auto"
          filled
          auto-grow
          @input="update('answer', $event)"
        />
      </validation-provider>
    </validation-observer>
  </v-col>
</template>

<script>

  export default {
    props: {
      value: {
        type: Object,
      },
      question: {
        type: Object,
        default: () => { return {} },
      },
      validation: {
        type: Object,
        default: () => { return {} },
      },
    },

    data: () => ({ }),

    computed: {
      rows () {
        if (!this.question.characterLimit) {
          return 5
        }

        const rows = parseInt(this.question.characterLimit / 50)

        return rows > 0 ? rows : 1
      }
    },

    watch: {
      validation (errors) {
        this.$refs.observer.setErrors(errors)
      }
    },

    mounted () { },

    methods: {
      update(key, value) {
        const allAnswers = { ...this.value }
        allAnswers[this.question.id] = { ... allAnswers[this.question.id], [key]: value }
        this.$emit('input', allAnswers)
      },
    },

  }
</script>
